import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, image, description, tags, meta = [] }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const defaultTitle = title
    ? `${title} | ${description}`
    : siteMetadata?.title;
  const defaultDescription = description || siteMetadata?.description;
  const defaultImage =
    image ||
    'https://res.cloudinary.com/dmcookpro/image/upload/v1629124806/const/og-const.jpg';
  const defaultTags = tags || 'javascript,js,webdevelopement,webdev';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={defaultTitle}
      meta={[
        {
          name: 'description',
          content: defaultDescription,
        },
        {
          name: 'keywords',
          content: defaultTags,
        },
        {
          name: 'og:title',
          content: defaultTitle,
        },
        {
          name: 'og:site_name',
          content: defaultTitle,
        },
        {
          name: 'og:url',
          content: 'https://addurl.com/',
        },
        {
          name: 'og:description',
          content: defaultDescription,
        },
        {
          name: 'og:type',
          content: 'website',
        },
        {
          name: 'og:image',
          content: defaultImage,
        },
        {
          name: 'twitter:site',
          content: '@constjavascript',
        },
        {
          name: 'twitter:creator',
          content: '@constjavascript',
        },
        {
          name: 'twitter:title',
          content: defaultTitle,
        },
        {
          name: 'twitter:description',
          content: defaultDescription,
        },
        {
          name: 'twitter:image',
          content: defaultImage,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
